<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                            <b-row>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-table-simple class="table" bordered responsive>
                                                        <tr>
                                                            <th>{{ $t('bsri_demandDIS.fiscal_year') }}</th>
                                                            <td>{{ summarySheetData.fiscal_year }}</td>
                                                            <th>{{ $t('bsri_demandDIS.mill_name') }}</th>
                                                            <td>{{ summarySheetData.mill_name }}</td>
                                                        </tr>
                                                    </b-table-simple>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3">
                                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.mill_information') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <b-table-simple class="table table-bordered" responsive>
                                                        <thead>
                                                            <tr>
                                                                <th style="vertical-align : middle;text-align:center;" rowspan="3">{{ $t('globalTrans.sl_no') }}</th>
                                                                <th style="vertical-align : middle;text-align:center;" rowspan="3">{{ $t('bsri_demandDIS.subzone_office_name') }}</th>
                                                                <th style="vertical-align : middle;text-align:center;" rowspan="3">{{ $t('bsri_demandDIS.total_farmer') }}</th>
                                                                <th class="text-center" colspan="8">{{ $t('bsri_config.cultivation_method_name') }}</th>
                                                            </tr>
                                                            <tr>
                                                                <th colspan="2" v-for="(item, index) in cultivationMethod" :key="index">
                                                                    {{ item.text }}
                                                                    <div v-if="summarySheetData.fiscal_year_id === item.fiscal_year_id && cultivationMethod.value === item.method_cultivation_id">
                                                                    ( {{ $t('bsri_demandDIS.acr_unit_first') }} {{ $n(cultivationPolicy[0].text) }} {{ $t('bsri_demandDIS.acr_unit_second') }} )
                                                                    </div>
                                                                </th>
                                                                <th style="vertical-align : middle;text-align:center;" colspan="2">{{ $t('bsri_demandDIS.total') }}</th>
                                                            </tr>
                                                            <tr>
                                                            </tr>
                                                        </thead>
                                                        <b-tbody  v-for="(info, index3) in detailsData" :key="index3">
                                                            <b-td class="text-center">{{  $n(index3 + 1) }}</b-td>
                                                            <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.sub_zone_ofc_name_bn : info.sub_zone_ofc_name }}</b-td>
                                                            <b-td class="text-center">{{ $n(info.total_farmers) }}</b-td>
                                                            <template v-for="(item4, index4) in cultivationMethod">
                                                                <td :key="index4+1">{{ info.cultivation_info[index4 + 1] ? $n(info.cultivation_info[index4 + 1].land_quantity) : $i18n.locale === 'bn' ? '০' : '0' }}</td>
                                                                <td :key="index4+2"> {{ info.cultivation_info[index4 + 1] ? $n(info.cultivation_info[index4 + 1].amount) : $i18n.locale === 'bn' ? '০' : '0' }}</td>
                                                                </template>
                                                            <b-td class="text-center">{{ $n(info.total_quantity) }}</b-td>
                                                            <b-td class="text-center">{{ $n(info.total_amount) }}</b-td>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <ValidationProvider name="Comments (En)" vid="comments" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="2"
                                                            label-for="comments"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.comments_en')}}
                                                        </template>
                                                        {{ summarySheetData.comments }}
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="2"
                                                            label-for="comments_bn"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.comments_bn')}}
                                                        </template>
                                                        {{ summarySheetData.comments_bn }}
                                                        </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <div class="col text-right">
                                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-details')">{{ $t('globalTrans.cancel') }}</b-button>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { summarySheetDetails } from '../../api/routes'

export default {
    props: ['id', 'fiscalYearId'],
    components: {
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            onlyCreate: true,
            summarySheetData: {
                comments: '',
                comments_bn: '',
                fiscal_year_id: 0,
                mill_info_id: ''
            },
            detailsData: []
        }
    },
    created () {
        if (this.id) {
            this.getEditingData()
            this.loadData()
        }
    },
    watch: {
    },
    mounted () {
        core.index()
    },
    computed: {
        cultivationMethod: function () {
            const cultivationMethodList = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.filter(item => item.status === 1)
            return cultivationMethodList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        cultivationPolicy: function () {
            const cultivationPolicy = this.$store.state.incentiveGrant.commonObj.cultivationPolicy.filter(item => item.status === 1)
            return cultivationPolicy.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.per_acr_amount }
                } else {
                    return { value: item.value, text: item.per_acr_amount }
                }
            })
        }
    },
    methods: {
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            const data = JSON.parse(JSON.stringify(Object.assign({}, tmpData)))
            this.summarySheetData.fiscal_year_id = data.fiscal_year_id
            this.summarySheetData.fiscal_year = this.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year_en
            this.summarySheetData.mill_name = this.$i18n.locale === 'bn' ? data.mill_name_bn : data.mill_name
            this.summarySheetData.total_farmers = data.total_farmers
            this.summarySheetData.mill_info_id = data.mill_info_id
        },
        loadData () {
            // const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.postData(incentiveGrantServiceBaseUrl, summarySheetDetails, this.summarySheetData).then(response => {
                if (response.success) {
                this.detailsData = this.getRelatinalData(response.data)
                }
            })
        },
        getRelatinalData (data) {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        const subZoneStore = this.$store.state.incentiveGrant.commonObj.subZoneList.filter(item => item.status === 1)
        const listData = data.map(item => {
            const subZoneOfficeObject = subZoneStore.find(subZoneItem => subZoneItem.value === item.subzone_office_id)
            const subZoneData = {
                sub_zone_ofc_name: subZoneOfficeObject !== undefined ? subZoneOfficeObject.text_en : ' ',
                sub_zone_ofc_name_bn: subZoneOfficeObject !== undefined ? subZoneOfficeObject.text_bn : ' '
            }
            const fiscalYearObj = fiscalYearList.find(fiscalYear => fiscalYear.value === item.fiscal_year_id)
            const fiscalYearData = { fiscal_year_en: fiscalYearObj !== undefined ? fiscalYearObj.text_en : '', fiscal_year_bn: fiscalYearObj !== undefined ? fiscalYearObj.text_bn : '' }
            return Object.assign({}, item, fiscalYearData, subZoneData)
        })
        return listData
        }
    }
}
</script>
