<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                            <b-row>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-table-simple class="table" bordered responsive>
                                                        <tr>
                                                            <th>{{ $t('bsri_demandDIS.fiscal_year') }}</th>
                                                            <td>{{ summarySheetData.fiscal_year }}</td>
                                                            <th>{{ $t('bsri_demandDIS.mill_name') }}</th>
                                                            <td>{{ summarySheetData.mill_name }}</td>
                                                        </tr>
                                                    </b-table-simple>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3">
                                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.mill_information') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <b-table-simple class="table table-bordered" responsive>
                                                        <thead>
                                                            <tr>
                                                                <th style="vertical-align : middle;text-align:center;" rowspan="3">{{ $t('globalTrans.sl_no') }}</th>
                                                                <th style="vertical-align : middle;text-align:center;" rowspan="3">{{ $t('bsri_demandDIS.subzone_office_name') }}</th>
                                                                <th style="vertical-align : middle;text-align:center;" rowspan="3">{{ $t('bsri_demandDIS.total_farmer') }}</th>
                                                                <th class="text-center" colspan="8">{{ $t('bsri_config.cultivation_method_name') }}</th>
                                                            </tr>
                                                            <tr>
                                                                <th colspan="2" v-for="(item, index) in cultivationMethod" :key="index">
                                                                    {{ item.text }}
                                                                    <div v-if="summarySheetData.fiscal_year_id === item.fiscal_year_id && cultivationMethod.value === item.method_cultivation_id">
                                                                    ( {{ $t('bsri_demandDIS.acr_unit_first') }} {{ $n(cultivationPolicy[0].text) }} {{ $t('bsri_demandDIS.acr_unit_second') }} )
                                                                    </div>
                                                                </th>
                                                                <th style="vertical-align : middle;text-align:center;" colspan="2">{{ $t('bsri_demandDIS.total') }}</th>
                                                            </tr>
                                                            <tr>
                                                            </tr>
                                                        </thead>
                                                        <b-tbody  v-for="(info, index3) in detailsData" :key="index3">
                                                            <b-td class="text-center">{{  $n(index3 + 1) }}</b-td>
                                                            <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.sub_zone_ofc_name_bn : info.sub_zone_ofc_name }}</b-td>
                                                            <b-td class="text-center">{{ $n(info.total_farmers) }}</b-td>
                                                            <template v-for="(item4, index4) in cultivationMethod">
                                                                <td :key="index4+1">{{ info.cultivation_info[index4 + 1] ? $n(info.cultivation_info[index4 + 1].land_quantity) : $i18n.locale === 'bn' ? '০' : '0' }}</td>
                                                                <td :key="index4+2"> {{ info.cultivation_info[index4 + 1] ? $n(info.cultivation_info[index4 + 1].amount) : $i18n.locale === 'bn' ? '০' : '0' }}</td>
                                                                </template>
                                                            <b-td class="text-center">{{ $n(info.total_quantity) }}</b-td>
                                                            <b-td class="text-center">{{ $n(info.total_amount) }}</b-td>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <ValidationProvider name="Comments (En)" vid="comments" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="2"
                                                            label-for="comments"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.comments_en')}}
                                                        </template>
                                                            <b-form-textarea
                                                            id="description"
                                                            v-model="summarySheetData.comments"
                                                            rows="1"
                                                            max-rows="2"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <ValidationProvider name="Comments (Bn)" vid="comments_bn" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="2"
                                                            label-for="comments_bn"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.comments_bn')}}
                                                        </template>
                                                            <b-form-textarea
                                                            id="description"
                                                            v-model="summarySheetData.comments_bn"
                                                            rows="1"
                                                            max-rows="2"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <div class="col text-right">
                                                        <b-button type="submit" variant="primary" class="mr-2"> {{ $t('bsri_demandDIS.propose_confirm') }} </b-button>
                                                        &nbsp;
                                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { summarySheetStore, summarySheetDetails } from '../../api/routes'

export default {
    props: ['id', 'fiscalYearId'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            onlyCreate: true,
            summarySheetData: {
                comments: '',
                comments_bn: '',
                fiscal_year_id: 0,
                mill_info_id: ''
            },
            detailsData: []
        }
    },
    created () {
        if (this.id) {
            this.getEditingData()
            this.loadData()
        }
    },
    watch: {
    },
    mounted () {
        core.index()
    },
    computed: {
        cultivationMethod: function () {
            const cultivationMethodList = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.filter(item => item.status === 1)
            return cultivationMethodList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        cultivationPolicy: function () {
            const cultivationPolicy = this.$store.state.incentiveGrant.commonObj.cultivationPolicy.filter(item => item.status === 1)
            return cultivationPolicy.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.per_acr_amount }
                } else {
                    return { value: item.value, text: item.per_acr_amount }
                }
            })
        }
    },
    methods: {
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            const data = JSON.parse(JSON.stringify(Object.assign({}, tmpData)))
            this.summarySheetData.fiscal_year_id = data.fiscal_year_id
            this.summarySheetData.fiscal_year = this.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year_en
            this.summarySheetData.mill_name = this.$i18n.locale === 'bn' ? data.mill_name_bn : data.mill_name
            this.summarySheetData.total_farmers = data.total_farmers
            this.summarySheetData.mill_info_id = data.mill_info_id
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, summarySheetStore, this.summarySheetData)
            }
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false
            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.postData(incentiveGrantServiceBaseUrl, summarySheetDetails, this.summarySheetData).then(response => {
                if (response.success) {
                this.detailsData = this.getRelatinalData(response.data)
                }
            })
        },
        getRelatinalData (data) {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        const subZoneStore = this.$store.state.incentiveGrant.commonObj.subZoneList.filter(item => item.status === 1)
        const listData = data.map(item => {
            const subZoneOfficeObject = subZoneStore.find(subZoneItem => subZoneItem.value === item.subzone_office_id)
            const subZoneData = {
                sub_zone_ofc_name: subZoneOfficeObject !== undefined ? subZoneOfficeObject.text_en : ' ',
                sub_zone_ofc_name_bn: subZoneOfficeObject !== undefined ? subZoneOfficeObject.text_bn : ' '
            }
            const fiscalYearObj = fiscalYearList.find(fiscalYear => fiscalYear.value === item.fiscal_year_id)
            const fiscalYearData = { fiscal_year_en: fiscalYearObj !== undefined ? fiscalYearObj.text_en : '', fiscal_year_bn: fiscalYearObj !== undefined ? fiscalYearObj.text_bn : '' }
            return Object.assign({}, item, fiscalYearData, subZoneData)
        })
        return listData
        }
    }
}
</script>
